<template>
    <div class="modal-wrapper">
        <div class="modal modal-feedback">
            <div class="modal__close" @click="closeModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M1 1L17 17" stroke="black" stroke-width="2" stroke-linecap="round"/>
                    <path d="M17 1L1 17" stroke="black" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </div>
            <div class="modal-feedback__title">
                Связаться с нами
            </div>
            <div class="modal-feedback__subtitle">
                Опишите вашу задачу и мы с вами свяжемся или напишите нам удобным вам способом
            </div>
            <form class="modal-feedback-body">
                <input type="text" class="input" placeholder="Как вас зовут">
                <input type="tel" class="input" placeholder="Телефон">
                <input type="text" class="input" placeholder="Расскажите о задаче">
                <div class="checkbox">
                    <input type="checkbox" id="privacy">
                    <label for="privacy">Согласен с <a href="#">политикой конфиденциальности</a></label>
                </div>
                <div class="modal-feedback-body-buttons">
                    <button class="btn" type="submit">Оставить заявку</button>
                    <button class="btn btn_outline">Прикрепить файл</button>
                </div>
            </form>
            <div class="modal-feedback-socials">
                <a href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <rect width="40" height="40" rx="20" fill="#0088CC"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.51023 18.8319C14.957 16.4593 18.5887 14.895 20.4055 14.139C25.5944 11.9824 26.6782 11.6079 27.3751 11.594C27.6336 11.589 27.8871 11.6651 28.0999 11.8117C28.2493 11.9405 28.3446 12.1209 28.3665 12.3169C28.4041 12.5606 28.4146 12.8077 28.3979 13.0538C28.1174 16.0151 26.8994 23.1729 26.2809 26.4809C26.0195 27.8745 25.5038 28.3501 25.0037 28.3971C23.9199 28.4964 23.087 27.6794 22.0416 26.9913C20.3968 25.913 19.4681 25.2493 17.872 24.1902C16.0268 22.9708 17.2239 22.3071 18.2745 21.2167C18.5498 20.931 23.3275 16.583 23.4216 16.1893C23.434 16.1312 23.4326 16.071 23.4174 16.0135C23.3875 15.8996 23.2855 15.8148 23.1691 15.7972C23.1612 15.7961 23.1533 15.7951 23.1454 15.7943C23.0119 15.7817 22.8852 15.8472 22.7698 15.9155C22.0864 16.3198 19.5193 18.0242 15.0685 21.0285C14.3228 21.5407 13.6473 21.7904 13.0421 21.7776C12.3747 21.7619 11.0923 21.3996 10.1375 21.0808C8.96834 20.701 8.04662 20.5007 8.1198 19.8614C8.16278 19.5269 8.62625 19.1838 9.51023 18.8319Z" fill="white"/>
                    </svg>
                </a>
                <a href="#">
                    <svg width="40" height="40" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="50" height="50" rx="25" fill="#25D366"/>
                        <path d="M35.8799 14.3322C34.5429 12.9817 32.9504 11.9109 31.1952 11.1823C29.44 10.4537 27.5574 10.0818 25.657 10.0885C17.6945 10.0885 11.2049 16.578 11.2049 24.5405C11.2049 27.0926 11.8757 29.5718 13.1299 31.7593L11.0882 39.2551L18.7445 37.2426C20.8591 38.3947 23.2362 39.0072 25.657 39.0072C33.6195 39.0072 40.1091 32.5176 40.1091 24.5551C40.1091 20.6905 38.607 17.0593 35.8799 14.3322ZM25.657 36.5572C23.4987 36.5572 21.3841 35.9739 19.532 34.8801L19.0945 34.6176L14.5445 35.8135L15.7549 31.3801L15.4632 30.928C14.2641 29.0132 13.6274 26.7999 13.6257 24.5405C13.6257 17.9197 19.0216 12.5239 25.6424 12.5239C28.8507 12.5239 31.8695 13.778 34.1299 16.053C35.2491 17.1672 36.1361 18.4923 36.7394 19.9518C37.3427 21.4112 37.6504 22.9759 37.6445 24.5551C37.6737 31.176 32.2778 36.5572 25.657 36.5572ZM32.2486 27.5739C31.8841 27.3989 30.1049 26.5239 29.7841 26.3926C29.4487 26.276 29.2153 26.2176 28.9674 26.5676C28.7195 26.9322 28.0341 27.7489 27.8299 27.9822C27.6257 28.2301 27.407 28.2593 27.0424 28.0697C26.6778 27.8947 25.5112 27.501 24.1403 26.276C23.0612 25.3135 22.3466 24.1322 22.1278 23.7676C21.9237 23.403 22.0986 23.2135 22.2882 23.0239C22.4487 22.8635 22.6528 22.601 22.8278 22.3968C23.0028 22.1926 23.0757 22.0322 23.1924 21.7989C23.3091 21.551 23.2507 21.3468 23.1632 21.1718C23.0757 20.9968 22.3466 19.2176 22.0549 18.4885C21.7632 17.7885 21.457 17.876 21.2382 17.8614H20.5382C20.2903 17.8614 19.9111 17.9489 19.5757 18.3135C19.2549 18.678 18.3216 19.553 18.3216 21.3322C18.3216 23.1114 19.6195 24.8322 19.7945 25.0655C19.9695 25.3135 22.3466 28.9593 25.9632 30.5197C26.8237 30.8989 27.4945 31.1176 28.0195 31.278C28.8799 31.5551 29.6674 31.5114 30.2945 31.4239C30.9945 31.3218 32.4382 30.5489 32.7299 29.703C33.0361 28.8572 33.0362 28.1426 32.9341 27.9822C32.832 27.8218 32.6132 27.7489 32.2486 27.5739Z" fill="white"/>
                    </svg>       
                </a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>