<template>
    <footer class="footer">
        <div class="footer-wrapper container">
            <div class="footer-info">
                <a href="/">
                    <img src="@/assets/img/logo_white.svg" alt="Tcode">
                </a>
                <span>© 2023 Tcode</span>
            </div>
            <div class="footer-links-wrapper">
                <div class="footer-links">
                    <a href="/#">О нас</a>
                    <a href="/#services">Услуги</a>
                    <a href="/#clients">Клиенты</a>
                </div>
                <div class="footer-links">
                    <a href="/#cases">Кейсы</a>
                    <a href="/#steps">Этапы работы</a>
                    <a href="/#reviews">Отзывы</a>
                </div>
                <div class="footer-links">
                    <a href="/#articles">Статьи</a>
                    <a href="/#feedback">Контакты</a>
                </div>
            </div>
            <div class="footer-links-wrapper footer-links-wrapper_mobile">
                <div class="footer-links">
                    <a href="/#">О нас</a>
                    <a href="/#services">Услуги</a>
                    <a href="/#clients">Клиенты</a>
                    <a href="/#cases">Кейсы</a>
                </div>
                <div class="footer-links">
                    <a href="/#steps">Этапы работы</a>
                    <a href="/#reviews">Отзывы</a>
                    <a href="/#articles">Статьи</a>
                    <a href="/#feedback">Контакты</a>
                </div>
            </div>
            <div class="footer-socials">
                <a href="tel:88001016919">8 800 101-69-19</a>
                <div class="footer-socials-row">
                    <a href="#" class="feedback-socials__logo">
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                            <rect width="50" height="50" rx="25" fill="#0088CC"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.8877 23.5404C18.6961 20.5746 23.2358 18.6193 25.5067 17.6743C31.9928 14.9785 33.3476 14.5104 34.2188 14.493C34.5418 14.4867 34.8587 14.5819 35.1248 14.7652C35.3115 14.9261 35.4306 15.1517 35.4581 15.3966C35.505 15.7013 35.5181 16.0102 35.4973 16.3177C35.1466 20.0194 33.6242 28.9666 32.851 33.1016C32.5243 34.8436 31.8796 35.4381 31.2545 35.4969C29.8998 35.621 28.8587 34.5997 27.5519 33.7396C25.4958 32.3918 24.335 31.5622 22.3399 30.2383C20.0334 28.714 21.5297 27.8844 22.843 26.5213C23.1872 26.1642 29.1593 20.7292 29.2769 20.2371C29.2924 20.1645 29.2906 20.0892 29.2717 20.0173C29.2342 19.875 29.1068 19.769 28.9613 19.747C28.9514 19.7456 28.9415 19.7444 28.9316 19.7434C28.7647 19.7276 28.6064 19.8095 28.4622 19.8949C27.6078 20.4003 24.399 22.5307 18.8355 26.2862C17.9033 26.9263 17.059 27.2384 16.3025 27.2225C15.4683 27.2029 13.8653 26.75 12.6717 26.3515C11.2103 25.8768 10.0581 25.6264 10.1496 24.8272C10.2033 24.4092 10.7827 23.9802 11.8877 23.5404Z" fill="white"/>
                        </svg>
                    </a>
                    <a href="#" class="feedback-socials__logo">
                        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="50" height="50" rx="25" fill="#25D366"/>
                            <path d="M35.8799 14.3322C34.5429 12.9817 32.9504 11.9109 31.1952 11.1823C29.44 10.4537 27.5574 10.0818 25.657 10.0885C17.6945 10.0885 11.2049 16.578 11.2049 24.5405C11.2049 27.0926 11.8757 29.5718 13.1299 31.7593L11.0882 39.2551L18.7445 37.2426C20.8591 38.3947 23.2362 39.0072 25.657 39.0072C33.6195 39.0072 40.1091 32.5176 40.1091 24.5551C40.1091 20.6905 38.607 17.0593 35.8799 14.3322ZM25.657 36.5572C23.4987 36.5572 21.3841 35.9739 19.532 34.8801L19.0945 34.6176L14.5445 35.8135L15.7549 31.3801L15.4632 30.928C14.2641 29.0132 13.6274 26.7999 13.6257 24.5405C13.6257 17.9197 19.0216 12.5239 25.6424 12.5239C28.8507 12.5239 31.8695 13.778 34.1299 16.053C35.2491 17.1672 36.1361 18.4923 36.7394 19.9518C37.3427 21.4112 37.6504 22.9759 37.6445 24.5551C37.6737 31.176 32.2778 36.5572 25.657 36.5572ZM32.2486 27.5739C31.8841 27.3989 30.1049 26.5239 29.7841 26.3926C29.4487 26.276 29.2153 26.2176 28.9674 26.5676C28.7195 26.9322 28.0341 27.7489 27.8299 27.9822C27.6257 28.2301 27.407 28.2593 27.0424 28.0697C26.6778 27.8947 25.5112 27.501 24.1403 26.276C23.0612 25.3135 22.3466 24.1322 22.1278 23.7676C21.9237 23.403 22.0986 23.2135 22.2882 23.0239C22.4487 22.8635 22.6528 22.601 22.8278 22.3968C23.0028 22.1926 23.0757 22.0322 23.1924 21.7989C23.3091 21.551 23.2507 21.3468 23.1632 21.1718C23.0757 20.9968 22.3466 19.2176 22.0549 18.4885C21.7632 17.7885 21.457 17.876 21.2382 17.8614H20.5382C20.2903 17.8614 19.9111 17.9489 19.5757 18.3135C19.2549 18.678 18.3216 19.553 18.3216 21.3322C18.3216 23.1114 19.6195 24.8322 19.7945 25.0655C19.9695 25.3135 22.3466 28.9593 25.9632 30.5197C26.8237 30.8989 27.4945 31.1176 28.0195 31.278C28.8799 31.5551 29.6674 31.5114 30.2945 31.4239C30.9945 31.3218 32.4382 30.5489 32.7299 29.703C33.0361 28.8572 33.0362 28.1426 32.9341 27.9822C32.832 27.8218 32.6132 27.7489 32.2486 27.5739Z" fill="white"/>
                        </svg>                            
                    </a>
                </div>
                <span>© 2023 Tcode</span>
            </div>
        </div>
    </footer>
</template>