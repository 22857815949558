import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView
	},
	{
		path: '/articles',
		name: 'articles',
		component: function () {
			return import('../views/ArticlesView.vue')
		}
	},
	{
		path: '/articles',
		name: 'articles',
		component: function () {
			return import('../views/ArticlesView.vue')
		}
	},
	{
		path: '/cases',
		name: 'cases',
		component: function () {
			return import('../views/CasesView.vue')
		}
	},
	{
		path: '/contacts',
		name: 'contacts',
		component: function () {
			return import('../views/ContactsView.vue')
		}
	},
	{
		path: '/reviews',
		name: 'reviews',
		component: function () {
			return import('../views/ReviewsView.vue')
		}
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
